/**
 *  Footer Style
 **/

footer.site-footer {
  position: relative;
  color: $color-footer-primary;
  width: 100vw;
  margin-top: $section-top-space;
  text-align: center;
  content-visibility: auto;
  contain-intrinsic-height: 222px;

  // Footer Information Section
  .footer-info {
    padding-bottom: 1.0em;
    background-color: $color-footer-bg-lighter;

    .footer-info-text {
      font-size: 1.25rem;
      padding: 0.75em 0;
    }

    // Social Media Icons
    .social-icons {
      a {
        color: $color-footer-primary;

        &:hover,
        &:active {
          color: $color-footer-secondary;
        }
      }
    }
  }

  // Footer Legal Section
  .footer-legal {
    font-size: 1.0rem;
    padding: 1em 2em;
    background-color: $color-footer-bg-darker;

    .footer-text {
      a {
        color: $color-footer-primary;

        &:hover,
        &:active {
          color: $color-footer-secondary;
        }
      }
      p {
        margin-bottom: 0.75em;
      }
    }
  }
}