/**
 *  Social Icons Style
 **/

.social-icons {
  display: inline-block;
  font-size: 1.5em;

  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    sup {
      font-size: 50%;
      vertical-align: super;
    }

    &.icons {
      li {
        padding: 0.3em 0;

        a {
          img {
            height: 1.6em;
            width: 1.6em;
            opacity: 0.7;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }

  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.icons {
      li {
        float: left;
        padding: 0 0.5em;

        a {
          img {
            height: 1.5em;
            width: 1.5em;
            opacity: 0.7;
            filter: invert(1.0);

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }

}