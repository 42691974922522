/**
 *  Header Style
 **/
 
header.site-header {
  position: relative;
  width: 100vw;
  z-index: 1;

  .header-banner {
    position: absolute;
    top: 0;
    z-index: 2;
    color: $color-white;

    .heading-content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      text-transform: uppercase;
      text-align: center;
      transform: translate(-50%, calc(-50% - #{$heading-content-offset}));

      .heading-title {
        text-shadow: 3px 3px 8px #000000;

        hr.small {
          margin: 1.0em auto;
          opacity: 0.75;
          background-color: $color-white;
        }
      }

      .heading-buttons {
        margin-top: 2em;

        button {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
      }
    }

    img {
      width: 100vw;
      max-height: 100vh;
      margin: 0;
      padding: 0;
      object-fit: cover;
    }
  }

  &.hero-header {
    @include hero-height();

    .header-banner .heading-content {
      padding: 3em;
      max-width: 48em;
    }

    picture {      
      @include hero-height();

      img {
        @include hero-height();
      }  
    }
  }

  &.slim-header {
    object-fit: cover;
    object-position: center top;
    padding-bottom: 1em;

    img {
      height: 8em;
    }

    .header-banner {
      position: relative;
    }

    .heading-content {
      padding: 0;
      margin-top: 0.25em;

      .heading-title {
        h1 {
          font-size: 1.9em;

          @media (max-width: $mobile-break-xs) {
            font-size: 1.1em;
          }

          @media (max-width: $mobile-break) {
            font-size: 1.4em;
          }
        }

        hr.small {
          margin: 0.2em auto 0.7em;
        }
      }
    }

  }

  .arrow-down-section {
    position: absolute;
    text-align: center;
    width: 100%;
    height: $arrow-down-height;
    bottom: 10rem;
    z-index: 3;
    opacity: 0.60;

    .wrapper-arrow {
      animation: bounceDownUp 1.5s infinite;
      animation-delay: 6s;
    }
  }

}