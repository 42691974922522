/**
 *  Keyframe Definitions
 **/

@keyframes bounceDownUp {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(2em);
  }

  100% {
    transform: translateY(0);
  }
}